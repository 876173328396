import React from "react";
import { Typography } from "antd";
import ReactApexChart from "react-apexcharts";
import { eChart } from "./config/eChart";
const { Title, Paragraph } = Typography;
export default function Echat() {
  return (
    <>
      <div id="chart">
        <ReactApexChart
          series={eChart.series}
          options={eChart.options}
          height={220}
          className="bar-chart"
          type="bar"
        />
      </div>
      <div className="chart-vistior">
        <Title level={5}>Daily Usage</Title>
        <Paragraph className="lastweek">
          <span className="bnb2">+30%</span> used more than the day before
        </Paragraph>
        <Paragraph className="lastweek">
        This tool will enable you to monitor your daily electricity usage. Armed with this information, you can make well-informed decisions, leading to a more efficient use of electricity.
        </Paragraph>
      </div>
    </>
  );
}
