import React from "react";
import {
  information,
  newestElectricity,
  calender,
} from "utils/BillingData";
import {
  Row,
  Col,
  Card,
  List,
  Descriptions,
  Avatar,
} from "antd";
import moment from "moment";

export default function Billing() {
  const desItem = [
    {
      label: "Company Name",
      name: "Tswhane",
    },
    {
      label: "Email Address",
      name: "customercare@tshwane.gov.za",
    },
    {
      label: "VAT Number",
      name: "FRB1235476",
    },
  ];
  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} md={16} className="mb-24">
          <Card
            className="header-solid h-full"
            bordered={false}
            title={<h6 className="font-semibold m-0">Billing Information</h6>}
            bodyStyle={{ paddingTop: 0 }}
          >
            <Row gutter={[24, 24]}>
              {information.map((i, index) => (
                <Col span={24} key={index}>
                  <Card className="card-billing-info" bordered={false}>
                    <div className="col-info">
                      <Descriptions title="Electricity">
                        {desItem.map(({ name, label }, index) => (
                          <Descriptions.Item label={label} span={3} key={index}>
                            {name}
                          </Descriptions.Item>
                        ))}
                      </Descriptions>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        </Col>
        <Col span={24} md={8} className="mb-24">
          <Card
            bordered={false}
            // bodyStyle={{ paddingTop: 0 }}
            className="header-solid h-full  ant-list-yes"
            title={<h6 className="font-semibold m-0">Your Transactions</h6>}
            extra={
              <p className="card-header-date">
                {calender}
                <span>{moment().format('MMMM Do YYYY, h:mm:ss a')}</span>
              </p>
            }
          >
            <List
              header={<h6>NEWEST</h6>}
              className="transactions-list ant-newest"
              itemLayout="horizontal"
              dataSource={newestElectricity}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={item.title}
                    description={item.description}
                    avatar={
                      <Avatar size="small" className={item.textclass}>
                        {item.avatar}
                      </Avatar>
                    }
                  />
                  <div className="amount">
                    <span className={item.amountcolor}>{item.amount}</span>
                  </div>
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
}
