import React from "react";
import { Layout, Row, Col } from "antd";
import { Iconify } from "utils/Iconify";
const { Footer } = Layout;
export default function AntdFooter() {
  return (
    <Footer style={{ backgroundColor: "#fafafa" }}>
      <Row className="just">
        <Col xs={24} md={12} lg={12}>
          <div className="copyright">
            © 2024, Mampudi Labs{" "}
            {
              <Iconify
              icon="akar-icons:battery-full"
              style={{ color: "green", fill: "green" }}
            />
            }
          </div>
        </Col>
      </Row>
    </Footer>
  );
}
