import {
  Card,
  Row,
  Col,
  Typography,
} from "antd";
import Echat from "components/charts/Echat";
import LineChart from "components/charts/LineChart";
import { count } from "utils/HomeData";
import { Iconify } from "utils/Iconify";
import AntCard from "components/AntCard";
import card from "../assets/images/info-card-1.jpg";
const { Title, Paragraph, Text } = Typography;
export default function Home() {
  return (
    <>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {count.map((c, index) => {
            return (
              <Col
                key={index}
                xs={24}
                sm={24}
                md={12}
                lg={6}
                xl={6}
                className="mb-24"
              >
                <Card bordered={false} className="criclebox">
                  <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                      <Col xs={18}>
                        <span>{c.today}</span>
                        <Title level={3}>
                          {c.title} <small className={c.bnb}>{c.persent}</small>
                        </Title>
                      </Col>
                      <Col xs={6}>
                        <div className="icon-box">{c.icon}</div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
        <Row gutter={[24, 0]}>
          <AntCard xl={10}>
            <Echat />
          </AntCard>
          <AntCard xl={14}>
            <LineChart />
          </AntCard>
        </Row>
        <Row gutter={[24, 0]}>
          <AntCard xl={14}>
            <Row>
              <Col xs={24} md={12} sm={24} lg={12} xl={14} className="mb-24">
                <div className="h-full col-content p-20">
                  <div className="ant-muse">
                    <Text>Resource Efficiency</Text>
                    <Title level={5}>Optimize Energy Utilization</Title>
                    <Paragraph className="lastweek mb-36">
                    You will find comprehensive documentation covering everything from geysers and solar panels to inverters and intricate battery strategies
                    </Paragraph>
                  </div>
                  <div className="card-footer">
                    <a href="#mampudi" className="icon-move-right">
                      Read more{" "}
                      {
                        <Iconify
                          icon="eva:chevron-right-fill"
                          width="17px"
                          height="17px"
                        />
                      }
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={12} sm={24} lg={12} xl={10} className="col-img">
                <div className="ant-cret text-right">
                  <img src={card} className="border10" alt="" />
                </div>
              </Col>
            </Row>
          </AntCard>
          <AntCard xl={10} cardClass="card-info-2">
            <div className="gradent h-full col-content">
              <div className="card-content">
                <Title level={5}>Work with the best</Title>
                <p>
                  Wealth creation is an evolutionarily recent positive-sum game.
                  It is all about who take the opportunity first.
                </p>
              </div>
              <div className="card-footer">
                <a href="#mampudi" className="icon-move-right">
                  Read more{<Iconify icon="eva:chevron-right-fill" />}
                </a>
              </div>
            </div>
          </AntCard>
        </Row>
      </div>
    </>
  );
}
